import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription, filter, finalize } from 'rxjs';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { ChangePasswordErrorCode } from '../../../core/domain/account/model/change-password-error.model';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { CountDownHelper } from '@features/auth/shared/helpers/start-countdown.helper';
import { AuthService } from '@features/auth/core/domain/login/auth-version-2.service';
import Swal from 'sweetalert2';
import { AccountSettingService } from '@features/auth/core/domain/account/account-setting.service';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputPasswordComponent],
  providers: [],
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Output() success = new EventEmitter();
  private readonly service = inject(AccountSettingService);
  private readonly authService = inject(AuthService);
  private readonly toart = inject(ToastrService);
  private readonly appRouting = inject(AppRoutingService);

  changePasswordForm: FormGroup = this.formBuilder.group(
    {
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    },
    {
      validators: [
        this.passwordsMatchValidator,
        this.newPasswordMatchValidator,
      ],
    }
  );
  username: string = '';

  showcCurrentPasswordError: boolean = false;
  displayErrorMessage = '';

  showPassword: boolean = false;
  showWaitingToChangePassword: boolean = false;

  secondsRemaining: number = 12;
  private subscription!: Subscription;
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  setCoutDown() {
    this.subscription = CountDownHelper.startCountdown(
      15,
      (second) => (this.secondsRemaining = second)
    );
  }
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {}

  // Kiểm tra pass và new pass bằng nhau
  private passwordsMatchValidator(
    group: FormGroup | any
  ): { [key: string]: boolean } | null {
    const password = group?.get('newPassword')?.value;
    const confirmPassword = group?.get('confirmPassword')?.value;

    if (password == '' && confirmPassword == '') {
      return null;
    }
    return password === confirmPassword ? null : { passwordsNotMatch: true };
  }

  // Mật khẩu mới không được giống mật khẩu cũ
  private newPasswordMatchValidator(
    group: FormGroup | any
  ): { [key: string]: boolean } | null {
    const password = group?.get('currentPassword')?.value;
    const newPassword = group?.get('newPassword')?.value;
    if (password == '' && newPassword == '') {
      return null;
    }

    return password !== newPassword
      ? null
      : { newPasswordMatchOldPassword: true };
  }

  onChangePassword() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.displayErrorMessage = '';
    this.showWaitingToChangePassword = true;

    const { currentPassword, newPassword } =
      this.changePasswordForm.getRawValue();
    this.service
      .changePassword(currentPassword, newPassword)
      .pipe(
        filter((response) => response == true),
        finalize(() => {
          this.showWaitingToChangePassword = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.success.emit();
          this.showConfirmToNavigateToLoginPage();
        },
        error: (err) => {
          console.error(err);
          this.displayErrorMessage = err.message;
        },
      });
  }

  showConfirmToNavigateToLoginPage() {
    this.authService.logout().subscribe({
      next: (value) => {
        Swal.fire({
          allowOutsideClick: false,
          title: 'Chúc mừng! Mật khẩu của bạn đã được đổi thành công',
          text: `Vui lòng chuyển hướng sang trang đăng nhập và thực hiện đăng nhập lại`,
          icon: 'success',
          confirmButtonText: 'Đưa tôi đến trang đăng nhập',
        }).then((result) => {
          if (result.isConfirmed) {
            this.appRouting.navigateToLoginPage();
          }
        });
      },
      error: (err) => {
        this.toart.error(
          `Xảy ra lỗi khi chuyển hướng sang trang đăng nhập vì ${err.message}, vui lòng thử lại`
        );
      },
    });
  }
}
