<h3 class="mb-4">Đổi mật khẩu</h3>

<div class="alert alert-info">
  <strong>Yêu cầu mật khẩu:</strong> Mật khẩu của bạn phải dài từ 8 đến 20 ký tự, phải chứa ít nhất 1 ký tự viết thường, 1 ký tự số và không có khoảng trắng.
</div>

<div class="row">
  <div class="col-md-6">
    <form [formGroup]="changePasswordForm">
      <!-- #Header -->
      <!-- #Body -->

      <!-- #Mật khẩu hiện tại -->
      <div class="form-group">
        <app-input-password
          [disabled]="this.changePasswordForm.disabled"
          [label]="'Mật khẩu hiện tại'"
          (changePassword)="
            this.changePasswordForm.get('currentPassword')?.setValue($event)
          "
          placeholder="Mật khẩu hiện tại"
          [setErrorMessage]="this.displayErrorMessage"
        >
        </app-input-password>

        <!-- SHOW ERROR WHEN CALL API SERVER CHECK VALID PASSWORD -->
        <div class="help-block" *ngIf="this.showcCurrentPasswordError">
          Mật khẩu cũ không khớp, vui lòng kiểm tra lại
        </div>
      </div>

      <!-- #Mật khẩu mới -->
      <div class="form-group">
        <!-- Check khoong trung tai khoan -->
        <app-input-password
          [label]="'Mật khẩu mới'"
          [class]="'form-control'"
          placeholder="Mật khẩu mới"
          [disabled]="this.changePasswordForm.disabled"
          (changePassword)="
            this.changePasswordForm.get('newPassword')?.setValue($event)
          "
          [matchOldPassword]="
            this.changePasswordForm.hasError('newPasswordMatchOldPassword')
          "
        >
        </app-input-password>
      </div>

      <!-- #Nhập lại mật khẩu mới -->
      <div class="form-group mb-6">
        <app-input-password
          [label]="'Nhập lại mật khẩu mới'"
          [class]="'form-control'"
          [disabled]="this.changePasswordForm.disabled"
          placeholder="Nhập lại mật khẩu mới"
          (changePassword)="
            this.changePasswordForm.get('confirmPassword')?.setValue($event)
          "
          [setErrorMessage]="
            this.changePasswordForm.hasError('passwordsNotMatch')
              ? 'Mật khẩu mới và xác nhận mật khẩu không khớp'
              : ''
          "
        >
        </app-input-password>
      </div>

      <!-- #Footer -->
      <button
        type="button"
        class="btn btn-primary waves-effect waves-themed" style="width: 9rem;"
        (click)="this.onChangePassword()"
        [ngClass]="{
          'cursor-default': this.changePasswordForm.disabled || this.changePasswordForm.invalid,
        }"
        [disabled]="
          this.changePasswordForm.disabled || this.changePasswordForm.invalid
        "
      >
        <span
          *ngIf="this.showWaitingToChangePassword"
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        <span> Đổi mật khẩu </span>
      </button>
    </form>
  </div>
</div>
